import { dbg } from './sharedSrc/src/debug/debug';

export async function loadTuner() {
  try {
    const tunerUrl = require('./tuner.js').default;

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = tunerUrl;
      script.type = 'module'; // Add this to treat it as an ES module
      script.async = true;

      script.onload = async () => {
        try {
          // Try to import the module dynamically
          const tunerModule = await import(/* webpackIgnore: true */ tunerUrl);
          const tunerInstance = await tunerModule.default();
          dbg.log('Tuner successfully loaded');
          resolve(tunerInstance);
        } catch (err) {
          reject(err);
        }
      };

      script.onerror = () => reject(new Error(`Failed to load tuner script: ${tunerUrl}`));
      document.body.appendChild(script);
    });
  } catch (error) {
    dbg.err('Failed to load tuner:', error);
    throw error;
  }
}

export const tunerPromise = loadTuner();
export {tunerPromise as CreateMyStrobeTuner};