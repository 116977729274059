import React, { useRef, useMemo, useEffect, MutableRefObject } from 'react';
import { extend, useThree, useFrame, ReactThreeFiber } from 'react-three-fiber';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass';

import * as THREE from 'three';

extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass, FilmPass });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      unrealBloomPass: ReactThreeFiber.Object3DNode<UnrealBloomPass, typeof UnrealBloomPass>;
    }
  }
}
// const blue: THREE.Color = new THREE.Color('blue');

export function EffectWithBloom() {
  const composer: MutableRefObject<any> = useRef();
  const { scene, gl, size, camera } = useThree();
  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size]);
  useEffect(() => void composer.current.setSize(size.width, size.height), [size]);
  useFrame(() => composer.current.render(), 1);
  /* eslint-disable react/no-unknown-property */
  return (

    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray='passes' scene={scene} camera={camera} />
      <unrealBloomPass attachArray='passes' args={[aspect, 1, 0.5, 0]} />
    </effectComposer>
  );
  /* eslint-enable react/no-unknown-property */
}
