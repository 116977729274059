import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { dbg } from '../sharedSrc/src/debug/debug';

// Your Google AdSense Publisher ID
const ADSENSE_PUBLISHER_ID = 'ca-pub-9954896376614754'; // Replace with your actual publisher ID

export const AdSenseInitializer: React.FC = () => {
  useEffect(() => {
    // Only load AdSense in production to avoid warnings during development
    if (process.env.NODE_ENV === 'production') {
      const script = document.createElement('script');
      script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${ADSENSE_PUBLISHER_ID}`;
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    }
  }, []);

  return null;
};

/**
 * Valid positions for ad placement within the application layout
 * - 'top': Displays at the top of the page
 * - 'bottom': Displays at the bottom of the page
 * - 'inline': Displays within content
 * - 'right': Displays on the right side
 */
export type AdPosition = 'top' | 'bottom' | 'inline' | 'right';

/**
 * Standard ad sizes supported by the application
 * - 'banner': 468x60 pixels
 * - 'leaderboard': 728x90 pixels
 * - 'large-rectangle': 336x280 pixels
 * - 'skyscraper': 160x600 pixels
 * Note... Currently only 'large-rectangle' is used, and leaderboard might shortly be used.
 */
export type AdSize = 'banner' | 'leaderboard' | 'large-rectangle' | 'skyscraper';

/**
 * Props for configuring an advertisement container
 * @interface AdContainerProps
 * @property {AdPosition} position - Where to place the ad in the layout
 * @property {AdSize} size - The size format of the advertisement
 * @property {string} [className] - Optional CSS class name for additional styling
 * @property {string} [slot] - Optional AdSense slot ID. If not provided, a default slot will be used
 * based on position and size
 */
interface AdContainerProps {
  position: AdPosition;
  size: AdSize;
  className?: string;
  slot?: string; // Your ad slot ID from AdSense
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    adContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      background: theme.palette.background.default,
    },
    // Position styles
    top: {
      width: '100%',
      maxWidth: '728px',
      margin: '0 auto',
    },
    bottom: {
      width: '100%',
      maxWidth: '728px',
      margin: '0 auto',
    },
    inline: {
      margin: '20px auto',
    },
    right: {
      height: '100%',
    },
    // Size styles
    banner: {
      width: '468px',
      height: '60px',
    },
    leaderboard: {
      width: '728px',
      height: '90px',
    },
    'large-rectangle': {
      width: '336px',
      height: '280px',
    },
    skyscraper: {
      width: '160px',
      height: '600px',
    },
  }),
);

// Ad Slot IDs - replace these with your actual ad slot IDs from AdSense
const AD_SLOTS = {
  'top-leaderboard': '3474703979',
  'bottom-banner': '0000000001',
  'inline-rectangle': '6100867314',
  'right-skyscraper': '0000000001',
};

/**
 * Container for displaying Google AdSense advertisements
 * @param {AdContainerProps} props - The component properties
 */
export const AdContainer: React.FC<AdContainerProps> = ({
  position,
  size,
  className = '',
  slot,
}) => {
  const classes = useStyles();

  // Use predefined slots based on position and size if no slot provided
  const getSlot = (): string => {
    if (slot) return slot;

    // Use default slots based on position and size
    if (position === 'top' && size === 'leaderboard') return AD_SLOTS['top-leaderboard'];
    if (position === 'bottom' && size === 'banner') return AD_SLOTS['bottom-banner'];
    if (position === 'inline' && size === 'large-rectangle') return AD_SLOTS['inline-rectangle'];
    if (position === 'right' && size === 'skyscraper') return AD_SLOTS['right-skyscraper'];

    // Fallback to first slot if no match
    return Object.values(AD_SLOTS)[0];
  };

  // Environment check for development mode
  if (process.env.NODE_ENV !== 'production') {
    return (
      <div
        className={`${classes.adContainer} ${classes[position]} ${classes[size]} ${className}`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => dbg.log(`Ad clicked: ${position}-${size}`)}
      >
        <div style={{ textAlign: 'center', color: '#666' }}>
          <div>AD PLACEHOLDER</div>
          <div>
            {position}-{size}
          </div>
        </div>
      </div>
    );
  } else {
    const wresponsive = size === 'large-rectangle' ? 'true' : undefined;
    const astyle =
      size === 'large-rectangle'
        ? { display: 'block', width: '100%', height: '100%' }
        : { display: 'inline-block', width: '728px', height: '90px' };
    const ptrstyle = { ...astyle, cursor: 'pointer' };
    return (
      <div className={`${classes.adContainer} ${classes[position]} ${classes[size]} ${className}`}>
        <ins
          className='adsbygoogle'
          style={ptrstyle}
          data-ad-client={ADSENSE_PUBLISHER_ID}
          data-ad-slot={getSlot()}
          data-ad-format='auto'
          data-full-width-responsive={wresponsive}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: '(adsbygoogle = window.adsbygoogle || []).push({});',
          }}
        />
      </div>
    );
  }
};
