import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dispatch } from '../sharedSrc/src/redux/redux';
import { Store } from '@abstractions/redux_inst';
import * as midi from '../sharedSrc/src/headers/midi';

import {
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
  Typography,
  DialogContent,
  createStyles,
  makeStyles,
  Theme,
  IconButton,
} from '@material-ui/core';
import { DoneOutline, Loop, Mic, MicNone } from '@material-ui/icons';
import { dbg } from '../sharedSrc/src/debug/debug';
import {
  copyNoteElement,
  NoteElement,
  TuningElement,
} from '../sharedSrc/src/instruments/instrument_defs';
import { ApplyTuningToTuner } from '../sharedSrc/src/instruments/note_mapping';
import useLongPress from '../use_long_press';
import { useDispatch } from 'react-redux';
import useSnackbarHelp, { useSnackbarHelpOut } from '@abstractions/use_snackbar_help';
import { useNoteAverager } from '../sharedSrc/src/redux/redux';
import { MyDialogTitle } from '../components/my_dialog_title';
import { centsRound, deepEqual } from '../sharedSrc/src/utils/utils';
import '../App.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editTuningCard: {
      ...theme.shape,
      flex: 1,
      boxShadow: theme.shadows[2],
      backgroundColor: 'rgba(1.0,1.0,1.0,0.1)',
      margin: theme.spacing(0.5),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: 80,
    },
    button: {
      ...theme.shape,
    },
    editNoteCardDiv: {
      width: '100%',
      flexDirection: 'row',
      padding: 10,
      display: 'flex',
      justifyContent: 'space-around',
    },
  }),
);

type AdjustableItemProps = {
  label: string;
  text: string;
  disabled?: boolean;
  help: useSnackbarHelpOut;
  helpTextUp?: string;
  helpTextDown?: string;
  onUp?: () => void;
  onDown?: () => void;
  onLongUp?: () => void;
  onLongDown?: () => void;
};

function AdjustableItem(props: AdjustableItemProps) {
  const classes = useStyles();

  const enabled = !props.disabled;
  const minus = enabled ? '-' : ' ';
  const plus = enabled ? '+' : ' ';

  const onUp = useCallback(() => {
    if (enabled && props.onUp) {
      props.onUp();
    }
  }, [props, enabled]);

  const onLongUp = useCallback(() => {
    if (enabled && props.onLongUp) {
      props.onLongUp();
    }
  }, [props, enabled]);

  const onDown = useCallback(() => {
    if (enabled && props.onDown) {
      props.onDown();
    }
  }, [props, enabled]);

  const onLongDown = useCallback(() => {
    if (enabled && props.onLongDown) {
      props.onLongDown();
    }
  }, [props, enabled]);

  const longPressUp = useLongPress(onLongUp);
  const longPressDown = useLongPress(onLongDown);

  const labelLowerCase = useMemo(() => {
    return props.label.toLowerCase();
  }, [props.label]);

  const helpTextUp = props.helpTextUp ? props.helpTextUp : 'Increase the ' + labelLowerCase;
  const helpTextDown = props.helpTextDown ? props.helpTextDown : 'Decrease the ' + labelLowerCase;

  return (
    <div className={classes.editTuningCard}>
      <Typography variant='body1'>{props.label}</Typography>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={onUp}
          {...longPressUp}
          onMouseOver={() => {
            props.help.setHelp(helpTextUp);
          }}
        >
          <Typography className={classes.button}>{plus}</Typography>
        </IconButton>
        <Typography variant='body1'>{props.text}</Typography>
        <IconButton
          onClick={onDown}
          {...longPressDown}
          onMouseOver={() => {
            props.help.setHelp(helpTextDown);
          }}
        >
          <Typography>{minus}</Typography>
        </IconButton>
      </div>
    </div>
  );
}

export type EditNoteProps = {
  open: boolean;
  element: NoteElement;
  refFreq: number;
  forceShowApplyButton: boolean;
  onClose: (isUpdated?: NoteElement) => void;
};

export function EditNoteDialog(props: EditNoteProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const help = useSnackbarHelp();
  const [noteIdx, setNoteIdx] = useState<midi.NoteIdx>(midi.NoteIdx.A);
  const [noteStr, setNoteStr] = useState('');
  const [octave, setOctave] = useState<number>(4);
  const [octaveStr, setOctaveStr] = useState('');
  const [noteAndOctaveStr, setNoteAndOctaveStr] = useState('');
  const [listeningForRef, setListeningForRef] = useState(false);

  const [cents, setCents] = useState(0);
  const [freq, setFreq] = useState(440);
  const [freqStr, setFreqStr] = useState('');
  const [restoreNoteFromBackup, setRestoreNoteFromBackup] = useState(false);
  const [disallowedNames, setDisallowedNames] = useState<Map<string, boolean>>(new Map());
  const [showApplyButton, setShowApplyButton] = useState(true);

  const [noteElementBackup, setNoteElementBackup] = useState<undefined | NoteElement>(undefined);
  const [incomingNote, setIncomingNote] = useState<undefined | NoteElement>(undefined);
  const [handleIncomingNote, setHandleIncomingNote] = useState(false);

  const [noteIsDirty, setNoteIsDirty] = useState(false);

  useEffect(() => {
    // Trigger on all props changes
    setHandleIncomingNote(true);
    setIncomingNote(props.element);
  }, [props]);

  useEffect(() => {
    if (handleIncomingNote) {
      setHandleIncomingNote(false);
      if (!!incomingNote) {
        // Only run if there is a new note on the way in.
        if (!deepEqual(noteElementBackup, incomingNote, 2)) {
          setIncomingNote(undefined);

          // Backup the note so it can be reverted.
          const cp = copyNoteElement(incomingNote);
          setNoteElementBackup(cp);
          setRestoreNoteFromBackup(true);
          setNoteIsDirty(false);

          const tuning = incomingNote.parent as undefined | TuningElement;
          if (tuning) {
            const m = new Map<string, boolean>();
            tuning.noteElement.forEach((n: NoteElement) => {
              m.set(n.name, true);
            });
            m.delete(incomingNote.name);
            setDisallowedNames(m);
          }
        }
      }
    }
  }, [incomingNote, handleIncomingNote, noteElementBackup]);

  useEffect(() => {
    if (noteElementBackup && restoreNoteFromBackup) {
      setRestoreNoteFromBackup(false);
      const noteAndOctave = midi.ParseNote(noteElementBackup.name);
      let _octave = noteAndOctave.invalidOctave ? -1 : noteAndOctave.octave;
      _octave = Math.max(-1, _octave);
      const _note = noteAndOctave.note;
      const _cents = noteElementBackup.cents ? parseFloat(noteElementBackup.cents) : 0;
      setNoteIdx(_note);
      setOctave(_octave);
      setCents(_cents);
      setNoteIsDirty(false);
    }
  }, [noteElementBackup, restoreNoteFromBackup]);

  // Convert note to string
  useEffect(() => {
    const nstr = midi.getCOffsetName(noteIdx);
    setNoteStr(nstr);
    setNoteAndOctaveStr(nstr + octaveStr);
  }, [noteIdx, octaveStr]);

  useEffect(() => {
    if (freq < 100) {
      setFreqStr(freq.toFixed(3));
    } else if (freq < 1000) {
      setFreqStr(freq.toFixed(2));
    } else {
      setFreqStr(freq.toFixed(1));
    }
  }, [freq]);

  // Convert octave to string
  useEffect(() => {
    if (octave < 0) {
      setOctaveStr('all');
    } else {
      const ostr = octave.toFixed(0);
      setOctaveStr(ostr);
      setNoteAndOctaveStr(noteStr + ostr);
    }
  }, [octave, noteStr]);

  // Convert note, octave, cents to frequency
  useEffect(() => {
    if (octave < 0) {
      const c: midi.NoteAndOctave = {
        note: noteIdx,
        octave: 4,
      };
      const f = midi.NoteAndOctaveGetFreqFromNoteAndError(c, cents);

      setFreq(f);
    } else {
      const c: midi.NoteAndOctave = {
        note: noteIdx,
        octave,
      };
      const f = midi.NoteAndOctaveGetFreqFromNoteAndError(c, cents);

      setFreq(f);
    }
  }, [noteIdx, octave, cents]);

  useEffect(() => {
    let _allowApplyButton = noteIsDirty || !!props.forceShowApplyButton;
    if (disallowedNames.size > 0 && noteAndOctaveStr.length > 0) {
      if (disallowedNames.has(noteAndOctaveStr)) {
        _allowApplyButton = false;
      }
    }
    setShowApplyButton(_allowApplyButton);
  }, [noteAndOctaveStr, disallowedNames, noteIsDirty, props]);

  const doneListening = useCallback(() => {
    setListeningForRef(false);
    setNoteIsDirty(true);
  }, []);

  const averagedNote = useNoteAverager(listeningForRef, (avg: number) => {
    // Convert average to note, octave, and cents.
    doneListening();
    const c: midi.GetNoteErrorResult = midi.GetNearestNoteAndError(avg);
    setNoteIdx(c.note);
    setOctave(c.octave);
  });
  if (listeningForRef) {
    dbg.log('EditNote::averager:', averagedNote);
  }

  useEffect(() => {
    // Just in case we will start listening for a note, apply chromatic tuning
    // to the tuner so it can hear everything.
    ApplyTuningToTuner(null, true);
    return () => {
      // Revert chromatic tuning
      const store = Store.inst().store;
      const state = store.getState();
      const currentTuning = state.system.currentTuning ? state.system.currentTuning : null;
      ApplyTuningToTuner(currentTuning, false);
    };
  }, []);

  const returnChangesToCaller = () => {
    // Apply the current changes to the dialog that spawned us
    const noteElement = noteElementBackup;
    if (noteElement && noteIsDirty) {
      noteElement.cents = cents.toFixed(1);
      const o = octave >= 0 ? octave : undefined;
      noteElement.name = midi.NoteIdxToString(noteIdx, o);
      if (noteElement.parent) {
        const tuning = noteElement.parent as TuningElement;
        tuning.userModified = true;
      }
    }
    props.onClose(noteElement);
  };

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <MyDialogTitle
        id='edit-note-dialog-title'
        onClose={() => {
          props.onClose();
        }}
      >
        Edit Note
      </MyDialogTitle>
      <DialogContent>
        <div className='Settings' style={{ flexDirection: 'column', flex: 1 }}>
          <div className={classes.editNoteCardDiv}>
            <AdjustableItem
              label={'Note'}
              help={help}
              text={noteStr}
              onUp={() => {
                setNoteIdx((noteIdx + 1) % 12);
                setNoteIsDirty(true);
              }}
              onDown={() => {
                const n = noteIdx === 0 ? 11 : noteIdx - 1;
                setNoteIdx(n);
                setNoteIsDirty(true);
              }}
            ></AdjustableItem>
            <AdjustableItem
              label={'Octave'}
              help={help}
              text={octaveStr}
              onUp={() => {
                setOctave(octave + 1);
                setNoteIsDirty(true);
              }}
              onDown={() => {
                const o = octave === -1 ? -1 : octave - 1;
                setOctave(o);
                setNoteIsDirty(true);
              }}
            ></AdjustableItem>
            <AdjustableItem
              label={'Cents'}
              help={help}
              text={cents.toFixed(1)}
              onUp={() => {
                setCents(centsRound(cents + 0.1));
                setNoteIsDirty(true);
              }}
              onLongUp={() => {
                setCents(centsRound(cents + 1));
                setNoteIsDirty(true);
              }}
              onDown={() => {
                setCents(centsRound(cents - 0.1));
                setNoteIsDirty(true);
              }}
              onLongDown={() => {
                setCents(centsRound(cents - 1));
                setNoteIsDirty(true);
              }}
            ></AdjustableItem>
            <AdjustableItem
              label={' = Hz'}
              help={help}
              text={listeningForRef ? averagedNote.average.toFixed(2) : freqStr}
              disabled={true}
            ></AdjustableItem>
          </div>
        </div>
      </DialogContent>
      <div className='Small' style={{ flexDirection: 'row', flex: 0.2 }}></div>
      <BottomNavigation
        style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}
        onChange={(event: React.ChangeEvent<{}>, newValue) => {
          dbg.log('onChange: with event ' + event, newValue);
          switch (newValue) {
            case 0:
              // Apply Button
              returnChangesToCaller();
              break;
            case 1:
              // Reset
              // setChosenTransposition(0);
              setRestoreNoteFromBackup(true);
              break;
            case 2:
              if (!listeningForRef) {
                dbg.log('EditNote::start audio');
                dispatch(Dispatch.startAudio(true));
                setListeningForRef(true);
                // setNumRefSamples(0);
                // setSampledAverage(currentValue);
                // setListeningForRef(true);
                help.setHelp('Listening for new note...');
              } else {
                // setListeningForRef(false);
                // setCurrentValue(sampledAverage);
                help.setHelp('Complete:' + averagedNote.average + 'Hz');
              }
              break;
            default:
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        <BottomNavigationAction
          disabled={!showApplyButton}
          label={!!showApplyButton ? 'Apply' : undefined}
          icon={!!showApplyButton ? <DoneOutline /> : undefined}
          onMouseEnter={() => {
            help.setHelp('Apply changes to the note');
          }}
          {...help.mouseEvents}
        />

        <BottomNavigationAction
          disabled={!noteIsDirty}
          label={!!noteIsDirty ? 'Reset' : undefined}
          icon={!!noteIsDirty ? <Loop /> : undefined}
          onMouseEnter={() => {
            help.setHelp('Reset your changes');
          }}
          {...help.mouseEvents}
        />

        <BottomNavigationAction
          label={listeningForRef ? 'Listening...' : 'Listen for Note'}
          icon={listeningForRef ? <MicNone /> : <Mic />}
          onMouseEnter={() => {
            help.setHelp('Sample the note from your instrument');
          }}
          {...help.mouseEvents}
        />
      </BottomNavigation>
      {help.Snackbar}
      <div className='Small' style={{ flexDirection: 'row', flex: 0.1 }}></div>
    </Dialog>
  );
}
