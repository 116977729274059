import React, { useRef, MutableRefObject } from 'react';
import { CanvasContext, useFrame } from 'react-three-fiber';
import { useSpring as useSpringGl, animated as animGl } from 'react-spring/three';
// import { useSpring as useSpringGl, animated as animGl } from 'react-spring';
import { IDLE_COLOR } from './gl-params';

// -----------------------------------------------------------------------------
export type TorusProps = {
  key?: string;
  harmonicIdx: number;
  active: boolean;
  diameter: number;
  amplitude: number;

  velocity: number;
  position: number;
  color: string;
};

const YPOS_3D_STUFF = 20;
// const YPOS_3D_STUFF_UNDER = YPOS_3D_STUFF - 2;
const ROTATION_AROUND_X = 0.1;

const torusDiameter = 1.6;
const ARC = (80 / 180) * Math.PI;

// -----------------------------------------------------------------------------
// Inner ring of the Torus. As the torusDiameter doesn't specify start and end
// angle, two of these make up one of the rings.
// -----------------------------------------------------------------------------
export function TorusInner(props: TorusProps) {
  const { velocity, diameter, amplitude, active, color } = props;
  const meshRef: MutableRefObject<any> = useRef();
  const velocityLocal = 2 * velocity;
  const radius = diameter / 2;
  const spring = useSpringGl({
    opacity: active ? Math.max(0.05, amplitude) : 0.2,
    color: active ? color : IDLE_COLOR,
  });

  useFrame((_state: CanvasContext, _delta: number) => {
    if (meshRef && meshRef.current && meshRef.current.rotation) {
      if (active) {
        meshRef.current.rotation.z += velocityLocal * _delta;
      } else {
        meshRef.current.rotation.z = 0 - ARC / 2;
      }
    }
  });

  /* eslint-disable react/no-unknown-property */
  const materialJsx = (
    // @ts-ignore - Suppress deep type instantiation error
    <animGl.meshPhysicalMaterial
      color={spring.color}
      transparent={true}
      reflectivity={0.9}
      flatShading={false}
      roughness={1}
      clearcoat={0.8}
      opacity={spring.opacity}
    />
  );

  return (
    <animGl.mesh
      ref={meshRef}
      position={[0, YPOS_3D_STUFF, 0]}
      rotation={[-Math.PI * ROTATION_AROUND_X, 0, 0]}
    >
      <animGl.mesh rotation={[0, 0, 0]}>
        <torusBufferGeometry attach='geometry' args={[radius, torusDiameter, 64, 12, ARC]} />
        {materialJsx}
      </animGl.mesh>
      <animGl.mesh rotation={[0, 0, Math.PI]}>
        <torusBufferGeometry attach='geometry' args={[radius, torusDiameter, 64, 12, ARC]} />
        {materialJsx}
      </animGl.mesh>
    </animGl.mesh>
  );
}
