import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from '@common/redux/redux';
import {
  BottomNavigation,
  BottomNavigationAction,
  DialogTitle,
  Dialog,
} from '@material-ui/core';
import {MyChooser} from './my_chooser';
import {
  Cancel as CancelIcon,
  DoneOutline as DoneOutlineIcon,
  Loop as LoopIcon,
} from '@material-ui/icons';
import {dbg} from '@common/debug/debug';
import {MyChooser_renderInstrument} from './choose_instrument';
import {RootState} from '@abstractions/redux_inst';
import {transpositionStrings} from '@common/headers/note_defs';
import '../../../../App.css';

const transpositionMap = new Map<string, number>();
transpositionStrings.forEach((value: string, index: number) => {
  transpositionMap.set(value, index);
});

export function TranspositionIdxToString(transposition: number): string {
  let idx = transposition ? transposition : 0;
  idx = Math.floor(idx) % 12;
  return transpositionStrings[idx];
}
export type ChooseTranspositionProps = {
  open: boolean;
  onClose: () => void;
};

export function ChooseTranspositionScreen(props: ChooseTranspositionProps) {
  const transposition = useSelector((state: RootState) => {
    return state.system.transposition;
  });

  const [chosenTransposition, setChosenTransposition] = useState(transposition);

  const dispatch = useDispatch();

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id="simple-dialog-title">Choose Transposition</DialogTitle>
      <div className="Settings" style={{flexDirection: 'column', flex: 1}}>
        <div style={{flexDirection: 'column', padding: 20, width: '100%'}}>
          <MyChooser
            options={transpositionStrings}
            label="Choose transposition"
            onChange={(_element: null | string) => {
              dbg.log('Chose transposition ' + _element);
              if (_element) {
                const _chosenIndex = transpositionMap.get(_element);
                const chosenIndex = _chosenIndex ? _chosenIndex : 0;
                setChosenTransposition(chosenIndex);
              }
            }}
            doRender={MyChooser_renderInstrument}
          />
        </div>
      </div>
      <div className="Small" style={{flexDirection: 'row', flex: 0.2}}></div>
      <BottomNavigation
        style={{width: '100%', paddingBottom: 10, paddingTop: 10}}
        onChange={(event: React.ChangeEvent<{}>, newValue) => {
          dbg.log('onChange: with event ' + event, newValue);
          switch (newValue) {
            case 0:
              // Accept
              dispatch(Dispatch.setTransposition(chosenTransposition));
              props.onClose();
              break;
            case 1:
              // Reset
              setChosenTransposition(0);
              break;
            case 2:
              // Cancel
              props.onClose();
              break;
            default:
              break;
          }
        }}
        showLabels
        // className={classes.root}
      >
        <BottomNavigationAction label="Apply" icon={<DoneOutlineIcon />} />
        <BottomNavigationAction label="Reset" icon={<LoopIcon />} />
        <BottomNavigationAction label="Cancel" icon={<CancelIcon />} />
      </BottomNavigation>
      <div className="Small" style={{flexDirection: 'row', flex: 0.1}}></div>
    </Dialog>
  );
}
