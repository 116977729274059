import {
  BottomNavigation,
  BottomNavigationAction,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { DoneOutline, AddCircleOutline, Loop, DeleteOutlined, Save } from '@material-ui/icons';
import React, { useState, useCallback, useMemo } from 'react';
import { TuningElement } from '../sharedSrc/src/instruments/instrument_defs';
import { getMouseEvents, MySnackbar } from '@abstractions/use_snackbar_help';

export interface TuningEditorBottomRowProps {
  chosenTuning?: null | TuningElement;
  isDirty: boolean;
  forceShowApplyButton?: boolean;
  isDefaultTuning?: boolean;
  userModified?: boolean;
  onApplyChanges: () => void;
  onRevertTuning: () => void;
  onDeleteTuningClick: () => void;
  onAddNoteClick: () => void;
  onSaveAsClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(2),

      width: '100%',
    },
  });
});

export function TuningEditorBottomRow(p: TuningEditorBottomRowProps) {
  const classes = useStyles();

  const [currentHelpString, setCurrentHelpString] = useState('');

  const clearTheHelpString = useCallback(() => {
    setCurrentHelpString('');
  }, []);

  // A simple function that returns mouse events for different buttons.
  const myGetMouseEvents = (strIn: string) =>
    getMouseEvents(
      strIn,
      (str: string) => {
        setCurrentHelpString(str);
      },
      () => {
        setCurrentHelpString('');
      },
    );

  const applyThisTuningProps = useMemo(() => myGetMouseEvents('Apply'), []);
  const addButtonProps = useMemo(() => myGetMouseEvents('Add a new note'), []);
  const resetToDefaultProps = useMemo(() => myGetMouseEvents('Reset to default'), []);
  const deleteThisTuningProps = useMemo(() => myGetMouseEvents('Delete this tuning'), []);
  const saveAsProps = useMemo(() => myGetMouseEvents('Save As...'), []);
  const hasResetButton = p.isDirty || (p.isDefaultTuning && p.userModified) ? true : false;
  const hasDeleteButton = !p.isDefaultTuning ? true : false;
  const showApplyButton =
    !!p.forceShowApplyButton || p.isDirty || (p.isDefaultTuning && p.userModified);
  // Create the plus button
  return (
    <BottomNavigation
      className={classes.root}
      showLabels={true}
      onChange={(_event: React.ChangeEvent<{}>, newValue) => {
        switch (newValue) {
          case 0:
            p.onApplyChanges();
            break;
          case 1:
            p.onAddNoteClick();
            break;
          case 2:
            p.onRevertTuning();
            break;
          case 3:
            p.onDeleteTuningClick();
            break;
          case 4:
            p.onSaveAsClick();
            break;

          default:
            break;
        }
      }}
    >
      <BottomNavigationAction
        {...applyThisTuningProps}
        disabled={!showApplyButton}
        label={!!showApplyButton ? 'Apply' : undefined}
        icon={!!showApplyButton ? <DoneOutline /> : undefined}
      ></BottomNavigationAction>

      <BottomNavigationAction
        {...addButtonProps}
        label={'Add new note'}
        icon={<AddCircleOutline />}
      ></BottomNavigationAction>

      <BottomNavigationAction
        {...resetToDefaultProps}
        disabled={!hasResetButton}
        label={
          !!!hasResetButton ? undefined : !!p.isDefaultTuning ? 'Reset to default' : 'Undo changes'
        }
        icon={!!hasResetButton ? <Loop /> : undefined}
      ></BottomNavigationAction>

      <BottomNavigationAction
        {...deleteThisTuningProps}
        disabled={!hasDeleteButton}
        label={!!hasDeleteButton ? 'Delete this tuning' : undefined}
        icon={!!hasDeleteButton ? <DeleteOutlined /> : undefined}
      ></BottomNavigationAction>

      <BottomNavigationAction
        {...saveAsProps}
        disabled={false}
        label={'Save As'}
        icon={<Save />}
      ></BottomNavigationAction>

      <MySnackbar
        help={currentHelpString}
        key={'lsjdafljsdfl'}
        onClose={() => {
          clearTheHelpString();
        }}
      ></MySnackbar>
    </BottomNavigation>
  );
}
