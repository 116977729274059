import { SHARP_NOTE_NAMES } from '../../../headers/midi';
import {
  DatunerNoteToNormalNote,
  NoteEvt,
  PollResults,
  StrobeEvt,
  StrobeEvtLen,
  ThreshEvt,
} from '../../../headers/note_defs';
import { CreateMyStrobeTuner } from '../../../../../tuner_ts';
import { dbg } from '../../../debug/debug';

class HeapAllocator {
  dataPtr?: any;
  dataHeap?: Uint8Array;
  _strobe?: any;

  constructor(
    _strobe: any,
    flt32Arr: null | Float32Array,
    int32Arr: null | Int32Array,
  ) {
    this._strobe = _strobe;
    const nDataBytes = flt32Arr
      ? flt32Arr.length * flt32Arr.BYTES_PER_ELEMENT
      : int32Arr
        ? int32Arr.length * int32Arr.BYTES_PER_ELEMENT
        : 0;
    this.dataPtr = _strobe._malloc(nDataBytes);
    this.dataHeap = new Uint8Array(
      _strobe.HEAPU8.buffer,
      this.dataPtr,
      nDataBytes,
    );

    if (this.dataHeap) {
      if (flt32Arr) {
        // Copy the contents of buffer.buffer into tmp_databuffer
        this.dataHeap.set(new Uint8Array(flt32Arr.buffer));
      } else if (int32Arr) {
        this.dataHeap.set(new Uint8Array(int32Arr.buffer));
      }
    }
  }

  getPtr(): any {
    return this.dataPtr;
  }

  free() {
    this._strobe._free(this.dataPtr);
  }
}

let _inst: StroboProCpp | null = null;

class StroboProCpp {
  _strobe: any = null;
  isInitialized: boolean = false;
  _tunerPromise: Promise<any> | null = null;
  evt_func?: (
    timestampMs: number,
    noteEvt?: NoteEvt,
    threshEvt?: ThreshEvt,
  ) => void = undefined;

  static inst(): StroboProCpp {
    if (null === _inst) {
      _inst = new StroboProCpp();

      _inst._tunerPromise = new Promise((resolve, reject) => {
        try {
          dbg.log('Attempting to initialize tuner');

          // Try loading using our wrapper
          CreateMyStrobeTuner
            .then((strobe: any) => {
              dbg.log('Successfully created tuner instance');
              if (_inst) {
                _inst._strobe = strobe;
              }

              if (_inst) {
                try {
                  _inst.WrapperInit(48000, 1024, 8);
                  resolve('ok');
                } catch (e) {
                  console.error('Exception while initializing detection algorithm:', e);
                  alert('Exception while initializing detection algorithm:' + e);
                  reject('Exception while initializing detection algorithm:' + e);
                }
              }
            })
            .catch((error: any) => {
              console.error('Error creating strobe tuner:', error);
              reject('Failed to initialize tuner: ' + error);
            });
        } catch (e) {
          console.error('Fatal error initializing tuner:', e);
          reject('Fatal error initializing tuner: ' + e);
        }
      });
    }
    return _inst;
  }

  // int WrapperInit(int fs, int fftSize, int oversampling, int decimation, float dcRemoveFreq);
  WrapperInit(
    fs: number = 48000,
    fftSize: number = 1024,
    oversampling: number = 4,
    decimation: number = 1,
    numStrobes: number = 5,
    strobesRefreshHz: number = 120,
    waitForPromise: boolean = true,
  ) {
    const doIt = () => {
      this._strobe._WrapperInit(
        fs,
        fftSize,
        oversampling,
        decimation,
        -1,
        -1,
        numStrobes,
        strobesRefreshHz,
      );

      // setTimeout(() => {
      //  this._strobe._WrapperChangeFftTriggerUpDownRate(0.2, 4);
      // }, 4000);
      this.WrapperChangeAutoSensDb(10);
      this.isInitialized = true;
    };
    if (!waitForPromise) {
      doIt();
      // dbg.log('WrapperInit completed.');
    } else if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        doIt();
      });
    }
  }

  WrapperSetCallback(
    evt_func?: (
      timestampMs: number,
      noteEvt?: NoteEvt,
      threshEvt?: ThreshEvt,
    ) => void,
  ) {
    this.evt_func = evt_func;
  }

  WrapperTraceEn() {
    // Todo: Implement this for web version
    // this._strobe._WrapperTraceEn(isDebug ? 1 : 0);
  }

  WrapperClearCallback(
    evt_func: (
      timestampMs: number,
      noteEvt?: NoteEvt,
      threshEvt?: ThreshEvt,
    ) => void,
  ) {
    if (evt_func === this.evt_func) {
      this.evt_func = undefined;
    }
  }

  // int WrapperChangeFS(int fs);
  WrapperChangeFs(fs: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeFS(fs);
      });
    }
  }

  WrapperChangeReferenceFreq(refFreq: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeReferenceFreq(refFreq);
        // dbg.log('WrapperChangeReferenceFreq completed.');
      });
    }
  }

  // int WrapperChangeManualSensDb(float manDb);
  WrapperChangeManualSensDb(manDb: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeManualSensDb(manDb);
      });
    }
  }

  // int WrapperChangeAutoSensDb(float autoDb);
  WrapperChangeAutoSensDb(autoDb: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperChangeAutoSensDb(autoDb);
      });
    }
  }

  // int WrapperUpdateNotes(
  //  int numNotes, float freqHzRel440Ary[], int noteAry[],
  //  int octaveAry[], int uniqueTagAry[]);
  _WrapperUpdateNotes(
    numNotes: number,
    freqHzRel440Ary: number,
    noteAry: number,
    octaveAry: number,
    uniqueTagAry: number,
  ): number {
    return this._strobe._WrapperUpdateNotes(
      numNotes,
      freqHzRel440Ary,
      noteAry,
      octaveAry,
      uniqueTagAry,
    );
  }

  WrapperUpdateNotes(
    freqHzRel440Ary: Float32Array,
    noteAry: Int32Array,
    octaveAry: Int32Array,
    uniqueTagAry: Int32Array,
  ): number {
    let rval = 0;
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        const freqs = new HeapAllocator(this._strobe, freqHzRel440Ary, null);
        const notes = new HeapAllocator(this._strobe, null, noteAry);
        const octaves = new HeapAllocator(this._strobe, null, octaveAry);
        const tags = new HeapAllocator(this._strobe, null, uniqueTagAry);

        rval = this._strobe._WrapperUpdateNotes(
          freqHzRel440Ary.length,
          freqs.getPtr(),
          notes.getPtr(),
          octaves.getPtr(),
          tags.getPtr(),
        );
        freqs.free();
        notes.free();
        octaves.free();
        tags.free();
      });
    }
    return rval;
  }

  WrapperSetMinFreqs(hpfFreq: number, minAnalFreq: number) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperSetMinFreqs(hpfFreq, minAnalFreq);
      });
    }
  }

  // int WrapperSendAudioPcm16(int16_t *pArr, int length);
  WrapperSendAudioPcm16(/*int16_t *pArr, int length*/) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperSendAudioPcm16();
      });
    }
  }

  // int WrapperSendAudioFloat(float *pArr, int length);
  _WrapperSendAudioFloat(pArr: number, length: number): number {
    if (!this._strobe) {
      return 0;
    } else {
      return this._strobe._WrapperSendAudioFloat(pArr, length);
    }
  }

  WrapperLockToFreq(
    fc: number,
    bwInCents: number = 30,
    numHarmonics = 5,
    alsoLockAnalysis = false, // todo: implement this!
  ) {
    if (this._tunerPromise) {
      this._tunerPromise.then(() => {
        this._strobe._WrapperLockToFreq(fc, bwInCents, numHarmonics, alsoLockAnalysis);
      });
    }
  }

  // int WrapperPoll(float *pResult, int length);
  WrapperPoll = (pResult: any, length: any): number => {
    const rval: number = this._strobe._WrapperPoll(pResult, length);
    return rval;
  };

  // This consumes too much of our CPU time.
  my_wrapper_report_result(resultArr: Float32Array) {
    const evt_func = this.evt_func;
    if (!evt_func) return;

    // Handle Signal events
    if (resultArr[PollResults.gotSigEvt] !== 0) {
      let timestampMs = Math.round(resultArr[PollResults.gotSigEvt]);
      timestampMs = timestampMs !== 0 ? timestampMs : Date.now();
      const numStrobesActive = resultArr[PollResults.strobesActive];
      let idx = PollResults.strobe0_amp;
      const strobes: StrobeEvt[] = new Array<boolean>(numStrobesActive)
        .fill(true)
        .map((_val: boolean, _index: number) => {
          const val: StrobeEvt = {
            amp: resultArr[idx + 0],
            position: resultArr[idx + 1],
            velocity: resultArr[idx + 2],
            lockFreq: resultArr[idx + 3],
            centsError: resultArr[idx + 4],
          };
          idx += StrobeEvtLen;
          return val;
        });

      const threshEvt: ThreshEvt = {
        analysisActive: resultArr[PollResults.analysisActive] > 0,
        dBFSSignal: resultArr[PollResults.dBFSSignal],
        dbFSThreshold: resultArr[PollResults.dbFSThreshold],
        dbFSNoiseFloor: resultArr[PollResults.dbFSNoiseFloor],
        strobesActive: resultArr[PollResults.strobesActive],
        strobeAry: strobes,
      };

      evt_func(timestampMs, undefined, threshEvt);
    }

    // Handle analysis events
    if (resultArr[PollResults.gotResult] !== 0) {
      const timestampMs = Math.round(resultArr[PollResults.gotResult]);
      let note = '';
      let octave = resultArr[PollResults.octave];
      const noteIdx = resultArr[PollResults.note];
      const no = DatunerNoteToNormalNote(noteIdx, octave);
      note = SHARP_NOTE_NAMES[no.n % 12];
      octave = no.o;

      evt_func(
        timestampMs,
        {
          note,
          octave,
          frequency: resultArr[PollResults.freqHz],
          centsError: resultArr[PollResults.centsError],
          iirLockFreq: resultArr[PollResults.iirLockFreq],
        },
        undefined,
      );
    }
  }

  // Globals used to prevent lots of allocating while running.
  dataPtrLen = 1;
  dataPtr: any = null; // = Module._malloc(1);
  dataHeap: null | Uint8Array = null;

  WrapperSendAudio(buffer: Float32Array): number {
    let rval = 0;
    if (this._strobe) {
      // this._strobe._WrapperSendAudioFloat(pArr, length);
      // Get data byte size, allocate memory on Emscripten heap, and get pointer
      const nDataBytes = buffer.length * buffer.BYTES_PER_ELEMENT;

      if (this.dataPtrLen !== nDataBytes) {
        this.dataPtrLen = nDataBytes;
        if (this.dataPtr) {
          this._strobe._free(this.dataPtr);
          this.dataPtr = null;
        }
        this.dataPtr = this._strobe._malloc(nDataBytes);
        this.dataHeap = new Uint8Array(
          this._strobe.HEAPU8.buffer,
          this.dataPtr,
          nDataBytes,
        );
      }

      if (this.dataHeap) {
        // Copy the contents of buffer.buffer into tmp_databuffer
        this.dataHeap.set(new Uint8Array(buffer.buffer));

        // Call function and get result
        // this.WrapperSendAudioFloat(this.dataHeap.byteOffset, buffer.length);
        rval = this._strobe._WrapperSendAudioFloat(
          this.dataHeap.byteOffset,
          buffer.length,
        );
      }
    }
    return rval;
  }

  MAX_STROBES = 7;
  STROBE_LENGTH = PollResults.strobe0_centsError - PollResults.strobe0_amp + 1;
  RESULT_LENGTH =
    PollResults.strobesActive + this.STROBE_LENGTH * this.MAX_STROBES + 1;

  wr_resultArr: Float32Array = new Float32Array(this.RESULT_LENGTH);
  wr_uint8ArrEncompassingHeap: Uint8Array | null = null;

  MyWrapperPoll = () => {
    let lastPollResult = -2;
    let thisPollResult = -1;

    if (!this.wr_uint8ArrEncompassingHeap) {
      const wr_resultBytes: number =
        this.RESULT_LENGTH * this.wr_resultArr.BYTES_PER_ELEMENT;
      // Do a "malloc" on the internal heap. Will return an "offset" into the HEAPU8 buffer of _strobe.
      const wr_resultHeapOffset = this._strobe._malloc(wr_resultBytes);

      // Get an array which incorporates the malloc'd data.
      this.wr_uint8ArrEncompassingHeap = new Uint8Array(
        this._strobe.HEAPU8.buffer,
        wr_resultHeapOffset,
        wr_resultBytes,
      );
      // Set the value of the arrEncompassingHeap to 0
      this.wr_uint8ArrEncompassingHeap.set(
        new Uint8Array(this.wr_resultArr.buffer),
      );
    }

    if (this.wr_uint8ArrEncompassingHeap) {
      while (lastPollResult !== thisPollResult && thisPollResult !== 0) {
        lastPollResult = thisPollResult;

        this.wr_resultArr[PollResults.gotResult] = 0;
        thisPollResult = this.WrapperPoll(
          this.wr_uint8ArrEncompassingHeap.byteOffset,
          this.wr_resultArr.length,
        );

        // Initialize the result
        const result = new Float32Array(
          this.wr_uint8ArrEncompassingHeap.buffer,
          this.wr_uint8ArrEncompassingHeap.byteOffset,
          this.wr_resultArr.length,
        );

        if (result[PollResults.gotResult] || result[PollResults.gotSigEvt]) {
          this.my_wrapper_report_result(result);
        }
      }
    }
  };

  WrapperAddSamples(buffer: Float32Array) {
    if (this.WrapperSendAudio(buffer)) {
      this.MyWrapperPoll();
    }
  }

  hi() {
    // dbg.log('hi');
  }
}

export { StroboProCpp };
