import React, {useRef, useEffect, useState, MutableRefObject} from 'react';

import {Knob2} from './knob2';
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import {
  Cancel as CancelIcon,
  DoneOutline as DoneOutlineIcon,
  Loop as LoopIcon,
  RemoveOutlined,
  AddOutlined,
  Mic,
  MicNone,
} from '@material-ui/icons';
import {dbg} from '@common/debug/debug';
import '../../../../App.css';

import useLongPress from '../../../../use_long_press';
import {Dispatch, useNoteAverager} from '../../redux/redux';
import {Store} from '@abstractions/redux_inst';
import {useDispatch} from 'react-redux';
import useSnackbarHelp from '@abstractions/use_snackbar_help';
import {roundFrequency} from '@common/utils/utils';
import {TuningElement} from '@common/instruments/instrument_defs';
import {ApplyTuningToTuner} from '@common/instruments/note_mapping';

export type KnobParamRange = {
  // Values for the knob.
  curr: number;
  min: number;
  max: number;
  default: number;
  rotations: number;
};

export type KnobParamProps = {
  val: KnobParamRange;
  onClose?: (value: number) => void;
  isAnimating?: boolean;
  labelPrefix?: string;
  labelPostfix?: string;
};

export type DimensionsType = {
  top: number;
  left: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

// -----------------------------------------------------------------------------
function getDeltaDegreesPerVal(val: KnobParamRange) {
  const {min, max, rotations} = val;
  const rangeVal = max - min;
  const rangeDegrees = rotations * 360;
  const degreesPerRotation = rangeDegrees / rangeVal;
  return degreesPerRotation;
}

// -----------------------------------------------------------------------------
function getDeltaValPerDegree(val: KnobParamRange) {
  return 1.0 / getDeltaDegreesPerVal(val);
}

// -----------------------------------------------------------------------------
function getRotationDegreesForVal(_val: KnobParamRange, curr?: number): number {
  const val = {..._val};
  if (curr !== undefined) {
    val.curr = curr;
  }
  const degrees = getDeltaDegreesPerVal(val) * (val.curr - val.min);
  return degrees;
}

// -----------------------------------------------------------------------------
function getValForRotationDegrees(
  val: KnobParamRange,
  currDegrees: number,
): number {
  // Return currDegrees * deltaValPerRotation + minVal
  const delta = getDeltaValPerDegree(val);
  const newVal = delta * currDegrees + val.min;
  return newVal;
}

const RAD2DEG = 360 / (2 * Math.PI);

// -----------------------------------------------------------------------------
// Returns 0...360
function getRotationDegreesFromMouseEvent(
  event: {x: number; y: number},
  knobCenter: {x: number; y: number},
) {
  const x = event.x - knobCenter.x;
  const y = event.y - knobCenter.y;
  const radians = Math.atan2(y, x);
  const degrees = radians * RAD2DEG;
  return 180 + degrees;
}

// -----------------------------------------------------------------------------
// Gets the difference in rotation.
function getRotationDelta(start: number, curr: number) {
  let delta = curr - start;
  if (delta < -180) {
    delta = delta + 360;
  } else if (delta > 180) {
    delta = delta - 360;
  }
  return delta;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    poopKnobPoop: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    poopArea: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    poopButton: {
      flex: 1,
    },
    knobArea: {
      minHeight: 250,
      minWidth: 250,
      alignItems: 'center',
      justifyContent: 'center',
    },
    freqText: {
      alignSelf: 'center',
      textAlign: 'center',
    },
  }),
);

interface IncDecProps {
  onClick: () => void;
  onLongPress: () => void;
  children?: any;
}

export function IncDecButton(props: IncDecProps) {
  const classes = useStyles();
  const longPress = useLongPress(props.onLongPress);

  return (
    <div className={classes.poopArea}>
      <Button
        className={classes.poopButton}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        {...longPress}
      >
        {props.children}
      </Button>
    </div>
  );
}

// Limit current value to between 220 and 660
function refLimit(currentValue: number) {
  let f = roundFrequency(currentValue);
  f = Math.max(220, f);
  f = Math.min(880, f);
  return f;
}

// -----------------------------------------------------------------------------
export function ParamKnob(props: KnobParamProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const mouseEvtRef: MutableRefObject<any> = useRef();
  const [knobAreaDims, setKnobArea] = useState<DimensionsType>({
    x: 0,
    y: 0,
    top: 0,
    left: 0,
    width: 100,
    height: 100,
  });

  const maxDegrees = props.val.rotations * 360;
  const pre = props.labelPrefix ? props.labelPrefix : '';
  const post = props.labelPostfix ? props.labelPostfix : '';

  const [knobCenter, setKnobCenter] = useState({x: 0, y: 0});
  const [rotation, setRotation] = useState(
    getRotationDegreesForVal(props.val, props.val.curr),
  );

  const [currentValue, setCurrentValue] = useState(props.val.curr);
  const [mouseRotationTrackerStart, setMouseRotationTrackerStart] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [listeningForRef, setListeningForRef] = useState(false);

  const [tuningBackup, setTuningBackup] = useState<null | TuningElement>(null);

  const help = useSnackbarHelp();

  // When rotation changes, set value.
  useEffect(() => {
    let newValue = getValForRotationDegrees(props.val, rotation);
    newValue = Math.round(newValue * 10) / 10;
    setCurrentValue(newValue);
  }, [rotation, props.val]);

  // Called when the window changes size.
  const updateDimensions = () => {
    const current: any = mouseEvtRef ? mouseEvtRef.current : null;
    if (current) {
      const _dims: DimensionsType = current.getBoundingClientRect().toJSON();

      const minDim = 0.75 * Math.min(_dims.width, _dims.height);
      setKnobArea({..._dims, height: minDim, width: minDim});
      setKnobCenter({
        x: _dims.x + _dims.width / 2,
        y: _dims.y + _dims.height / 2,
      });

      dbg.log(_dims);
    }
  };

  // Update dimensions on startup
  useEffect(() => {
    updateDimensions();
  }, [props.isAnimating]);

  // Re-run update dimensions if the window
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [mouseEvtRef]);

  // When averaging, limit value
  const averagedNote = useNoteAverager(
    listeningForRef,
    (avg: number) => {
      setListeningForRef(false);
      setCurrentValue(avg);
      help.setHelp('Complete:' + avg + 'Hz');
    },
    330,
    660,
  );

  useEffect(() => {
    dbg.log('Mounting param_knob');
    const store = Store.inst().store;
    const state = store.getState();
    const currentTuning = state.system.currentTuning
      ? state.system.currentTuning
      : null;
    setTuningBackup(currentTuning);
    ApplyTuningToTuner(currentTuning, true);

    return () => {
      dbg.log('Unmounting param_knob');
      ApplyTuningToTuner(tuningBackup, false);
    };
  }, [tuningBackup]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.poopKnobPoop}>
          <IncDecButton
            onClick={() => {
              setCurrentValue(refLimit(currentValue + 0.1));
            }}
            onLongPress={() => {
              setCurrentValue(refLimit(currentValue + 1.0));
            }}
          >
            <AddOutlined />
          </IncDecButton>
          {true && (
            <div
              className={classes.knobArea}
              ref={mouseEvtRef}
              style={{
                display: 'flex',
                flex: 1,
                padding: 0,
                margin: 0,
              }}
              onMouseDown={(
                event: React.MouseEvent<HTMLImageElement, MouseEvent>,
              ) => {
                const degrees = getRotationDegreesFromMouseEvent(
                  {x: event.clientX, y: event.clientY},
                  knobCenter,
                );
                setMouseRotationTrackerStart(degrees);
                setMouseDown(true);
                event.preventDefault();
              }}
              onMouseMove={(
                event: React.MouseEvent<HTMLImageElement, MouseEvent>,
              ) => {
                if (mouseDown) {
                  const degrees = getRotationDegreesFromMouseEvent(
                    {x: event.clientX, y: event.clientY},
                    knobCenter,
                  );
                  const delta = getRotationDelta(
                    mouseRotationTrackerStart,
                    degrees,
                  );
                  let rotationAccumulator = rotation + delta;
                  rotationAccumulator = Math.min(
                    maxDegrees,
                    rotationAccumulator,
                  );
                  rotationAccumulator = Math.max(0, rotationAccumulator);
                  setMouseRotationTrackerStart(degrees);
                  setRotation(rotationAccumulator);
                  event.preventDefault();
                }
              }}
              onMouseUp={() => {
                setMouseDown(false);
              }}
              onTouchStart={(event: React.TouchEvent<HTMLDivElement>) => {
                if (event.touches.length > 0) {
                  const t = event.touches.item(0);
                  const degrees = getRotationDegreesFromMouseEvent(
                    {x: t.clientX, y: t.clientY},
                    knobCenter,
                  );
                  setMouseRotationTrackerStart(degrees);
                  setMouseDown(true);
                  event.preventDefault();
                }
              }}
              onTouchMove={(event: React.TouchEvent<HTMLDivElement>) => {
                if (event.touches.length > 0) {
                  const t = event.touches.item(0);
                  if (mouseDown) {
                    const degrees = getRotationDegreesFromMouseEvent(
                      {x: t.clientX, y: t.clientY},
                      knobCenter,
                    );
                    const delta = getRotationDelta(
                      mouseRotationTrackerStart,
                      degrees,
                    );
                    let rotationAccumulator = rotation + delta;
                    rotationAccumulator = Math.min(
                      maxDegrees,
                      rotationAccumulator,
                    );
                    rotationAccumulator = Math.max(0, rotationAccumulator);
                    setMouseRotationTrackerStart(degrees);
                    setRotation(rotationAccumulator);
                    event.preventDefault();
                  }
                }
              }}
              onTouchEnd={() => {
                setMouseDown(false);
              }}
              onDrag={
                // tslint:disable-next-line
                () => {}
              }
            >
              {true && (
                <Knob2
                  degrees={rotation}
                  numTicks={60}
                  size={knobAreaDims.width}
                  min={0}
                  max={maxDegrees}
                  color={true}
                />
              )}
            </div>
          )}
          <IncDecButton
            onClick={() => {
              setCurrentValue(refLimit(currentValue - 0.1));
            }}
            onLongPress={() => {
              setCurrentValue(refLimit(currentValue - 1.0));
            }}
          >
            <RemoveOutlined />
          </IncDecButton>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" className={classes.freqText}>
          {pre}
          {listeningForRef ? averagedNote.average.toFixed(2) : currentValue}
          {post}
        </Typography>
      </Grid>
      <BottomNavigation
        showLabels
        style={{width: '100%', paddingBottom: 10, paddingTop: 10}}
        onChange={(_evt: React.ChangeEvent<{}>, newValue) => {
          dbg.log('newValue:', newValue);
          switch (newValue) {
            case 0:
              if (props.onClose) {
                props.onClose(
                  listeningForRef ? averagedNote.average : currentValue,
                );
              }
              setListeningForRef(false);

              break;
            case 1:
              setListeningForRef(false);
              setCurrentValue(props.val.default);
              setRotation(
                getRotationDegreesForVal(props.val, props.val.default),
              );

              break;
            case 2:
              setListeningForRef(false);
              if (props.onClose) {
                props.onClose(props.val.curr);
              }
              break;
            case 3:
              if (!listeningForRef) {
                dbg.log('start audio');
                dispatch(Dispatch.startAudio(true));
                setListeningForRef(true);
                help.setHelp('Listening for reference frequency...');
              } else {
                const val = roundFrequency(averagedNote.average);
                setListeningForRef(false);
                setCurrentValue(val);
                help.setHelp('Complete:' + val + 'Hz');
              }
              break;
            default:
              break;
          }
        }}
      >
        <BottomNavigationAction
          label="Apply"
          icon={<DoneOutlineIcon />}
          onMouseEnter={() => {
            help.setHelp('Apply reference freqency ' + currentValue + 'Hz');
          }}
          onMouseLeave={help.clearHelp}
        />
        <BottomNavigationAction
          label="Reset"
          icon={<LoopIcon />}
          onMouseEnter={() => {
            help.setHelp('Reset to default ' + 440 + 'Hz');
          }}
          onMouseLeave={help.clearHelp}
        />
        <BottomNavigationAction
          label="Cancel"
          icon={<CancelIcon />}
          onMouseEnter={() => {
            help.setHelp('Undo changes');
          }}
          onMouseLeave={help.clearHelp}
        />
        <BottomNavigationAction
          label={listeningForRef ? 'Listening...' : 'Listen for Reference'}
          icon={listeningForRef ? <MicNone /> : <Mic />}
          onMouseEnter={() => {
            help.setHelp('Start listening for reference frequency');
          }}
          onMouseLeave={help.clearHelp}
        />
      </BottomNavigation>

      {help.Snackbar}
    </Grid>
  );
}
