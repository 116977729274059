import {StroboProCpp} from './wrapper/js_wrapper';
import {AudioChunker} from '../../../../audio_chunker';
import {dbg} from '../../debug/debug';
import {
  ActionNames,
  CommitAudioEvents,
  Dispatch,
  InfoNames,
} from '../../redux/redux';
import {AUDIO_IN_DEFAULT} from '../../headers/note_defs';

import {Store} from '@abstractions/redux_inst';
import {call, takeEvery} from 'redux-saga/effects';

export const TARGET_DECIMATION = 4;
export const TARGET_MOTUNER_FS = 48000 / TARGET_DECIMATION;

export const NUM_STROBES = 4;

// true to use non-deprecated version
export const USE_WORKLET = true;

let _inst: undefined | TunerAudio;
export class TunerAudio {
  strobo: StroboProCpp;
  deviceIdOfCurrentDevice: string | null = null;
  decimation = TARGET_DECIMATION;
  bufferSize = 4096;
  motuner_sample_rate = 48000 / this.decimation;
  audioChunker = new AudioChunker();
  audioContext: AudioContext | null = null;
  audioPermissionsGranted: boolean = false;

  myPCMProcessingNode?: ScriptProcessorNode = undefined;

  // Audio nodes
  myAudioWorkletNode?: AudioWorkletNode = undefined;
  microphone: any = undefined;
  antiAliasLpf1?: BiquadFilterNode = undefined;
  antiAliasLpf2?: BiquadFilterNode = undefined;
  dcRemoveHpf?: BiquadFilterNode = undefined;
  workletProcessorLoaded: boolean = false;

  static inst(): TunerAudio {
    if (!_inst) {
      _inst = new TunerAudio();
    }
    const a: any = _inst;
    const tuner: TunerAudio = a;
    return tuner;
  }

  audioProcessorFunction = (audioProcessingEvent: AudioProcessingEvent) => {
    try {
      // Start reading the next buffer from the index left over from the last one.
      const inputBuffer = audioProcessingEvent.inputBuffer.getChannelData(0);
      this.audioChunker.chunkData(inputBuffer);
      setTimeout(CommitAudioEvents, 5);
      if (!this.audioPermissionsGranted) {
        this.audioPermissionsGranted = true;
        dbg.log(
          'AudioStreamStartedOk with device',
          this.deviceIdOfCurrentDevice,
        );
        if (
          !!this.deviceIdOfCurrentDevice &&
          this.deviceIdOfCurrentDevice.length > 0
        ) {
          Store.inst().store.dispatch(
            Dispatch.startedAudio(true, this.deviceIdOfCurrentDevice),
          );
        }
      }
    } catch (exception) {
      alert('Exception in wrapper_add_samples():' + exception);
    }
  };

  constructor() {
    this.strobo = StroboProCpp.inst();
  }

  // Load the audio worklet processor
  async loadAudioWorkletProcessor(audioContext: AudioContext): Promise<void> {
    if (this.workletProcessorLoaded) return;

    try {
      // Use an absolute path with current origin for better iOS compatibility
      const baseUrl = window.location.origin;
      // Try multiple loading strategies for better iOS compatibility
      await audioContext.audioWorklet.addModule(
        `${baseUrl}/audio_chunk_processor.js`,
      );
      this.workletProcessorLoaded = true;
      dbg.log('AudioWorklet processor loaded successfully');
    } catch (error) {
      dbg.err('Failed to load audio worklet:', error);
      throw error;
    }
  }

  // Enumerate the input devices in the system.
  static enumerateDevices(addToRedux: boolean = true) {
    const p = new Promise<MediaDeviceInfo[]>((resolve, _reject) => {
      if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
        alert(
          'Your device does not support WebAudio. Please try a recent version of Chrome, Firefox, or Safari',
        );
        resolve([]);
      } else {
        // Work in progress adding select input device.
        // https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
        navigator.mediaDevices
          .enumerateDevices()
          .then((deviceInfos: MediaDeviceInfo[]) => {
            const promiseRval: MediaDeviceInfo[] = [];
            deviceInfos.forEach((deviceInfo: MediaDeviceInfo) => {
              if (
                deviceInfo.kind === 'audioinput' &&
                deviceInfo.deviceId.length > 0
              ) {
                dbg.log('Got audio input', deviceInfo);
                promiseRval.push(deviceInfo);
                if (addToRedux) {
                  Store.inst().store.dispatch(
                    Dispatch.addAudioInputDevice(deviceInfo),
                  );
                }
              } else if (deviceInfo.kind === 'audiooutput') {
                dbg.log('Got audio output', deviceInfo);
              } else if (deviceInfo.kind === 'videoinput') {
                dbg.log('Got video input: ', deviceInfo);
              } else {
                dbg.log('Some other kind of source/device: ', deviceInfo);
              }
            });
            resolve(promiseRval);
          })
          .catch((reason: any) => {
            alert(
              'Your device does not support WebAudio. Please try a recent version of Chrome, Firefox, or Safari',
            );
            resolve([]);
          });
      }
    });
    return p;
  }

  startAudioScriptProcessor(selectedDeviceId: string): Promise<string> {
    const p: Promise<string> = new Promise((resolve, reject) => {
      setTimeout(async () => {
        this.motuner_sample_rate = 48000 / this.decimation;
        const wany: any = window;
        if (this.deviceIdOfCurrentDevice === selectedDeviceId) {
          dbg.log('Audio already set up');
          resolve('ok');
          return;
        }

        if (this.deviceIdOfCurrentDevice) {
          if (!!this.audioContext) {
            try {
              await this.audioContext.close();
              this.audioContext = null;
            } catch (e) {
              this.audioContext = null;
            }
          }
        }

        const AudioContext = window.AudioContext || wany.webkitAudioContext;
        this.audioContext = new AudioContext();

        // Aim for 30Hz refreshing /polling
        let decBufferSize = this.motuner_sample_rate / 60;

        // Ensure is a multiple of 32.
        decBufferSize = Math.floor(decBufferSize / 32);
        decBufferSize = Math.floor(decBufferSize * 32);

        // Reinitialize audio chunker.
        this.audioChunker.reInit(
          decBufferSize,
          this.decimation,
          (pArr: Float32Array) => {
            this.strobo.WrapperAddSamples(pArr);
          },
        );

        const minSampleRate = 11000;
        const minBufferSize = 512;
        const sampleRatio = Math.floor(
          this.audioContext.sampleRate / minSampleRate,
        );
        const sampleRatioLog2 = Math.round(Math.log(sampleRatio) / Math.log(2));
        const nearestLog2Ratio = Math.pow(2, sampleRatioLog2);

        try {
          this.bufferSize = minBufferSize * nearestLog2Ratio;
          this.decimation = Math.round(sampleRatio);

          this.motuner_sample_rate =
            this.audioContext.sampleRate / this.decimation;
          this.strobo.WrapperChangeFs(this.motuner_sample_rate);
        } catch (e) {
          alert('Web Audio API is not supported in this browser:' + e);
          reject(e);
          return;
        }

        if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
          alert(
            'Your device does not support WebAudio. Please try a recent version of Chrome, Firefox, or Safari',
          );
          reject('Device does not support web audio');
          return;
        } else {
          this.deviceIdOfCurrentDevice = selectedDeviceId;
          // Work in progress adding select input device.
          // https://github.com/webrtc/samples/blob/gh-pages/src/content/devices/input-output/js/main.js
          const audio =
            selectedDeviceId !== AUDIO_IN_DEFAULT
              ? {
                  deviceId: {exact: selectedDeviceId},
                }
              : true;

          const constraints: MediaStreamConstraints = {audio, video: false};
          navigator.mediaDevices.getUserMedia(constraints).then(
            // successful
            (mediaStream: MediaStream) => {
              // dbg.log('got mediastream', mediaStream);
              // Update sample rate to our decimated sample rate
              TunerAudio.inst().strobo.WrapperChangeFs(
                this.motuner_sample_rate,
              );
              const audioContext: null | AudioContext = this.audioContext;
              resolve('ok');
              this.connectMicrophoneToProcessingFunction(
                audioContext,
                mediaStream,
                minSampleRate,
              );
            },
            (error: any) => {
              dbg.err('MediaStreamError:', error);
              reject(error);
              return;
            },
          );
        }
      }, 1000);
    });
    return p;
  }

  startAudioWorklet(selectedDeviceId: string): Promise<string> {
    const p: Promise<string> = new Promise(async (resolve, reject) => {
      try {
        this.motuner_sample_rate = 48000 / this.decimation;
        const wany: any = window;
        if (this.deviceIdOfCurrentDevice === selectedDeviceId) {
          dbg.log('Audio already set up');
          resolve('ok');
          return;
        }

        // Close previous AudioContext and reset AudioWorklet state
        if (this.deviceIdOfCurrentDevice) {
          dbg.log('Stopping previous audio setup');
          await this.stopAudio(); // This will reset workletProcessorLoaded
        }

        // Create new AudioContext and ensure it's ready
        const AudioContext = window.AudioContext || wany.webkitAudioContext;
        this.audioContext = new AudioContext();

        // Ensure context is running (needed for Safari)
        if (this.audioContext.state !== 'running') {
          await this.audioContext.resume();
        }

        // Now load the AudioWorklet module for this context
        dbg.log('Loading AudioWorklet for new AudioContext');
        await this.loadAudioWorkletProcessor(this.audioContext);

        // Aim for 30Hz refreshing /polling
        let decBufferSize = this.motuner_sample_rate / 60;

        // Ensure is a multiple of 32.
        decBufferSize = Math.floor(decBufferSize / 32);
        decBufferSize = Math.floor(decBufferSize * 32);

        // Reinitialize audio chunker.
        this.audioChunker.reInit(
          decBufferSize,
          this.decimation,
          (pArr: Float32Array) => {
            this.strobo.WrapperAddSamples(pArr);
          },
        );

        const minSampleRate = 11000;
        const minBufferSize = 512;
        const sampleRatio = Math.floor(
          this.audioContext.sampleRate / minSampleRate,
        );
        const sampleRatioLog2 = Math.round(Math.log(sampleRatio) / Math.log(2));
        const nearestLog2Ratio = Math.pow(2, sampleRatioLog2);

        this.bufferSize = minBufferSize * nearestLog2Ratio;
        this.decimation = Math.round(sampleRatio);

        this.motuner_sample_rate =
          this.audioContext.sampleRate / this.decimation;
        this.strobo.WrapperChangeFs(this.motuner_sample_rate);

        if (!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
          alert(
            'Your device does not support WebAudio. Please try a recent version of Chrome, Firefox, or Safari',
          );
          reject('Device does not support web audio');
          return;
        } else {
          this.deviceIdOfCurrentDevice = selectedDeviceId;
          const audio =
            selectedDeviceId !== AUDIO_IN_DEFAULT
              ? {
                  deviceId: {exact: selectedDeviceId},
                }
              : true;

          const constraints: MediaStreamConstraints = {audio, video: false};
          const mediaStream = await navigator.mediaDevices.getUserMedia(
            constraints,
          );

          // Update sample rate to our decimated sample rate
          TunerAudio.inst().strobo.WrapperChangeFs(this.motuner_sample_rate);

          const audioContext: null | AudioContext = this.audioContext;
          await this.connectMicrophoneToProcessingFunction(
            audioContext,
            mediaStream,
            minSampleRate,
          );

          this.audioPermissionsGranted = true;
          dbg.log(
            'AudioStreamStartedOk with device',
            this.deviceIdOfCurrentDevice,
          );

          if (
            !!this.deviceIdOfCurrentDevice &&
            this.deviceIdOfCurrentDevice.length > 0
          ) {
            Store.inst().store.dispatch(
              Dispatch.startedAudio(true, this.deviceIdOfCurrentDevice),
            );
          }

          resolve('ok');
        }
      } catch (error) {
        dbg.err('Error starting audio:', error);
        reject(error);
      }
    });
    return p;
  }

  startAudio(selectedDeviceId: string): Promise<string> {
    return USE_WORKLET
      ? this.startAudioWorklet(selectedDeviceId)
      : this.startAudioScriptProcessor(selectedDeviceId);
  }

  stopAudio(): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        // Clean up audio nodes
        if (this.myAudioWorkletNode) {
          this.myAudioWorkletNode.disconnect();
          this.myAudioWorkletNode = undefined;
        }

        if (this.dcRemoveHpf) {
          this.dcRemoveHpf.disconnect();
          this.dcRemoveHpf = undefined;
        }

        if (this.antiAliasLpf1) {
          this.antiAliasLpf1.disconnect();
          this.antiAliasLpf1 = undefined;
        }

        if (this.antiAliasLpf2) {
          this.antiAliasLpf2.disconnect();
          this.antiAliasLpf2 = undefined;
        }

        if (this.microphone) {
          this.microphone.disconnect();
          this.microphone = undefined;
        }

        // If you're completely done with this audio context
        if (this.audioContext && this.audioContext.state !== 'closed') {
          await this.audioContext.close();
          this.audioContext = null;
          this.workletProcessorLoaded = false; // Reset this flag!
        }

        resolve('ok');
      } catch (error) {
        reject(error);
      }
    });
  }

  private async connectMicrophoneToProcessingFunctionWorklet(
    audioContext: AudioContext | null,
    mediaStream: MediaStream,
    minSampleRate: number,
  ) {
    if (!audioContext) return;

    // Get the microphone and create the filter chain
    this.microphone = audioContext.createMediaStreamSource(mediaStream);

    // Create DC removal high-pass filter
    this.dcRemoveHpf = audioContext.createBiquadFilter();
    this.dcRemoveHpf.type = 'highpass';
    this.dcRemoveHpf.frequency.value = 19;
    this.dcRemoveHpf.gain.value = 1;

    // Create the AudioWorkletNode
    this.myAudioWorkletNode = new AudioWorkletNode(
      audioContext,
      'audio-chunker-processor',
    );

    // Configure the worklet with our parameters
    this.myAudioWorkletNode.port.postMessage({
      type: 'config',
      bufferSize: Math.floor(this.bufferSize),
    });

    // Handle audio data from the worklet
    this.myAudioWorkletNode.port.onmessage = event => {
      if (event.data.type === 'audioData') {
        const audioData = event.data.audioData;
        this.audioChunker.chunkData(audioData);
        setTimeout(CommitAudioEvents, 5);
      }
    };

    // Add an antialiasing filter if the sample rate is too high
    if (this.decimation > 1) {
      const cutoffFreq = Math.min(
        this.motuner_sample_rate * 0.44,
        minSampleRate,
      );

      // Create antialiasing filters
      this.antiAliasLpf1 = audioContext.createBiquadFilter();
      this.antiAliasLpf1.type = 'lowpass';
      this.antiAliasLpf1.frequency.value = cutoffFreq;
      this.antiAliasLpf1.gain.value = 1;

      this.antiAliasLpf2 = audioContext.createBiquadFilter();
      this.antiAliasLpf2.type = 'lowpass';
      this.antiAliasLpf2.frequency.value = cutoffFreq;
      this.antiAliasLpf2.gain.value = 1;

      // Connect the audio graph with antialiasing
      this.microphone.connect(this.dcRemoveHpf);
      this.dcRemoveHpf.connect(this.antiAliasLpf1);
      this.antiAliasLpf1.connect(this.antiAliasLpf2);
      this.antiAliasLpf2.connect(this.myAudioWorkletNode);
    } else {
      // Connect the audio graph without antialiasing
      this.microphone.connect(this.dcRemoveHpf);
      this.dcRemoveHpf.connect(this.myAudioWorkletNode);
    }
  }

  private connectMicrophoneToProcessingFunctionScriptProcessor(
    audioContext: AudioContext | null,
    mediaStream: MediaStream,
    minSampleRate: number,
  ) {
    if (!!audioContext) {
      // Get the microphone and connect it to the high pass filter.
      const microphone = audioContext.createMediaStreamSource(mediaStream);
      this.dcRemoveHpf = audioContext.createBiquadFilter();
      this.dcRemoveHpf.type = 'highpass';
      this.dcRemoveHpf.frequency.value = 19;
      this.dcRemoveHpf.gain.value = 1;
      this.myPCMProcessingNode = audioContext.createScriptProcessor(
        this.bufferSize,
        1,
        1,
      );

      // Add an antialiasing filter if the sample rate is too high.
      if (this.myPCMProcessingNode) {
        if (this.decimation > 1) {
          const cutoffFreq = Math.min(
            this.motuner_sample_rate * 0.44,
            minSampleRate,
          );

          // Create an antialiasing filter and decimate the input
          this.antiAliasLpf1 = audioContext.createBiquadFilter();
          this.antiAliasLpf1.type = 'lowpass';
          this.antiAliasLpf1.frequency.value = cutoffFreq;
          this.antiAliasLpf1.gain.value = 1;
          this.antiAliasLpf2 = audioContext.createBiquadFilter();
          this.antiAliasLpf2.type = 'lowpass';
          this.antiAliasLpf2.frequency.value = cutoffFreq;
          this.antiAliasLpf2.gain.value = 1;

          // Connect the microphone to the antialiasing filter.
          microphone.connect(this.dcRemoveHpf);
          this.dcRemoveHpf.connect(this.antiAliasLpf1);
          this.antiAliasLpf1.connect(this.antiAliasLpf2);
          this.antiAliasLpf2.connect(this.myPCMProcessingNode);
        } else {
          microphone.connect(this.dcRemoveHpf);
          this.dcRemoveHpf.connect(this.myPCMProcessingNode);
        }
        this.myPCMProcessingNode.connect(audioContext.destination);
        // this.myPCMProcessingNode.onaudioprocess = this.audioProcessorFunction;
        this.myPCMProcessingNode.addEventListener(
          'audioprocess',
          this.audioProcessorFunction,
        );
      }
    }
  }

  private async connectMicrophoneToProcessingFunction(
    audioContext: AudioContext | null,
    mediaStream: MediaStream,
    minSampleRate: number,
  ) {
    if (USE_WORKLET) {
      await this.connectMicrophoneToProcessingFunctionWorklet(
        audioContext,
        mediaStream,
        minSampleRate,
      );
    } else {
      this.connectMicrophoneToProcessingFunctionScriptProcessor(
        audioContext,
        mediaStream,
        minSampleRate,
      );
    }
  }

  hi() {
    dbg.log('AudioIn::hello');
  }
}

function doSomethingAfterAppInit(args: any[]): string {
  // Runs after ActionNames.InitAction
  TunerAudio.inst().hi();

  // Enumerate devices and add the device list to redux store
  TunerAudio.enumerateDevices(true).then(arr => {
    Store.inst().store.dispatch(
      Dispatch.info(InfoNames.AudioInDevicesEnumerationAttempted),
    );
  });

  return 'doSomethingAfterAppInit done.';
}

function* onAppInit(actionAny: any, ..._otherArgs: any[]) {
  yield call(doSomethingAfterAppInit, actionAny);
}

function* onAppInitEffect() {
  yield takeEvery(ActionNames.InitAction, onAppInit);
}

export const AudioInSideEffects = [onAppInitEffect];
