import React, { useEffect, useState, useCallback } from 'react';
import * as THREE from 'three';

import { Canvas } from 'react-three-fiber';
import { Mic } from '@material-ui/icons';
import { Button, createStyles, makeStyles, Snackbar, Theme, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useWakeLock } from 'react-screen-wake-lock';
import { withRouter } from 'react-router-dom';

// Files from the path
import { NoteView } from './note_view';
import { BouncyView } from './bouncy_view';
import { CentsGl } from './components/cents-gl';
import { NoteTextGl } from './components/note-text-gl';

import {
  AudioState,
  AudioStateHolder,
  defaultAudioState,
  Dispatch,
  ReduxSpecificNote,
} from './sharedSrc/src/redux/redux';

import { PersistentDrawerLeft } from '@abstractions/persistent_drawer_left';
// import { dbg } from './sharedSrc/src/debug/debug';
import { EffectWithBloom } from './bloomy_bloom';

import { dbg } from './sharedSrc/src/debug/debug';
import { ElementBaseHelper } from './sharedSrc/src/instruments/instrument_defs';
import { RootState } from '@abstractions/redux_inst';

import { roundFrequencyStr } from '../src/sharedSrc/src/utils/utils';
import {
  AnimatedRhNoteSelector,
  RhNoteSelectorSelection,
  RH_DRAWER_SIZE,
} from './rh_note_selector';
import './App.css';
import { VuMeter } from './components/vu_meter';
import { TorusInner } from './components/torus-inner';
import CookieConsent from 'react-cookie-consent';
import { NUM_STROBES } from '@abstractions/audio_in';
import { useTranslation } from 'react-i18next';
import { transpositionStrings } from '@common/headers/note_defs';
import { AdContainer } from './components/AdContainer';

// Create an AnimatedText component from drei's text component.

const VERTICAL_OFFSET_INCLUDING_TITLE_SIZE = 160;

// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
interface CanvasDims {
  width: number;
  height: number;
}

// -----------------------------------------------------------------------------
function newCanvasDims(w: number, h: number): CanvasDims {
  return { width: w - 12, height: h - 12 };
}

// -----------------------------------------------------------------------------
interface TuningViewProps {
  canvasDims: CanvasDims;
}

class GlNoteData {
  noteString: string = '';
  octaveString: string = '';
  textColor: string = '';
  centsError: number = 0;
}

const innerDiameter = 32;
const diameterStep = 15;
// -----------------------------------------------------------------------------
function MyGlTuningView(p: TuningViewProps) {
  const [note, setNote] = useState('B4');
  const [sharp, setSharp] = useState('');
  const [incomingAudioState, setIncomingAudioState] = useState<AudioState>({
    ...defaultAudioState,
  });

  const [detectedNote, setDetectedNote] = useState<GlNoteData>({
    ...new GlNoteData(),
    noteString: 'S',
    octaveString: 'pro',
  });

  const myGlTuningViewThreshEvt = useCallback((_timestampMs: number, state: AudioState) => {
    setIncomingAudioState((prev) => {
      if (prev === null) {
        prev = defaultAudioState;
      }

      const newNote: GlNoteData = {
        noteString: state.noteString.length > 0 ? state.noteString : 'S',
        octaveString: state.octaveString.length > 0 ? state.octaveString : 'pro',
        textColor: state.textColor,
        centsError: state.noteEvt.centsError,
      };

      setDetectedNote(newNote);

      return state;
    });
  }, []);

  // const s = useThreshEvents();
  useEffect(() => {
    AudioStateHolder.inst().callbacks.subscribe(myGlTuningViewThreshEvt);
    return () => {
      AudioStateHolder.inst().callbacks.unsubscribe(myGlTuningViewThreshEvt);
    };
  }, [myGlTuningViewThreshEvt]);

  useEffect(() => {
    if (detectedNote.noteString.length >= 2) {
      setNote(detectedNote.noteString.slice(0, 1));
      setSharp(detectedNote.noteString.slice(1, 2));
    } else {
      setNote(detectedNote.noteString);
      setSharp('');
    }
  }, [detectedNote.noteString]);

  return (
    <div style={{ margin: 0, padding: 0 }}>
      <Canvas
        shadowMap
        camera={{ position: [0, 0, 80] }}
        style={{
          height: p.canvasDims.height,
          width: p.canvasDims.width,
          marginLeft: 6,
        }}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.ReinhardToneMapping;
          gl.setClearColor(new THREE.Color('#000000'));
        }}
        gl={{ antialias: true }}
      >
        {/* eslint-disable-next-line react/no-unknown-property */}
        <ambientLight intensity={100} />
        {/* eslint-disable-next-line react/no-unknown-property */}
        <spotLight position={[20, 20, 20]} angle={0.15} penumbra={1} />

        {false &&
          Array(NUM_STROBES).map((_v, index) => {
            return (
              <TorusInner
                key={'torus' + index}
                harmonicIdx={index}
                diameter={innerDiameter + index * diameterStep}
                velocity={incomingAudioState.strobesStateAry[index].relativeVelocityRadians}
                position={incomingAudioState.strobesStateAry[index].position}
                color={incomingAudioState.strobesStateAry[index].color}
                amplitude={incomingAudioState.strobesStateAry[index].relativeAmplitude}
                active={incomingAudioState.isAnalyzing}
              ></TorusInner>
            );
          })}
        {true && (
          <>
            <TorusInner
              harmonicIdx={0}
              diameter={innerDiameter + 0 * diameterStep}
              velocity={incomingAudioState.strobesStateAry[0].relativeVelocityRadians}
              position={incomingAudioState.strobesStateAry[0].position}
              color={incomingAudioState.strobesStateAry[0].color}
              amplitude={incomingAudioState.strobesStateAry[0].relativeAmplitude}
              active={incomingAudioState.isAnalyzing}
            ></TorusInner>
            <TorusInner
              harmonicIdx={1}
              diameter={innerDiameter + 1 * diameterStep}
              velocity={incomingAudioState.strobesStateAry[1].relativeVelocityRadians}
              position={incomingAudioState.strobesStateAry[1].position}
              color={incomingAudioState.strobesStateAry[1].color}
              amplitude={incomingAudioState.strobesStateAry[1].relativeAmplitude}
              active={incomingAudioState.isAnalyzing}
            ></TorusInner>
            <TorusInner
              harmonicIdx={2}
              diameter={innerDiameter + 2 * diameterStep}
              velocity={incomingAudioState.strobesStateAry[2].relativeVelocityRadians}
              position={incomingAudioState.strobesStateAry[2].position}
              color={incomingAudioState.strobesStateAry[2].color}
              amplitude={incomingAudioState.strobesStateAry[2].relativeAmplitude}
              active={incomingAudioState.isAnalyzing}
            ></TorusInner>
            <TorusInner
              harmonicIdx={3}
              diameter={innerDiameter + 3 * diameterStep}
              velocity={incomingAudioState.strobesStateAry[3].relativeVelocityRadians}
              position={incomingAudioState.strobesStateAry[3].position}
              color={incomingAudioState.strobesStateAry[3].color}
              amplitude={incomingAudioState.strobesStateAry[3].relativeAmplitude}
              active={incomingAudioState.isAnalyzing}
            ></TorusInner>
          </>
        )}

        <NoteTextGl
          text={note}
          isAnalyzing={incomingAudioState.isAnalyzing}
          textColor={detectedNote.textColor}
        ></NoteTextGl>
        <NoteTextGl
          text={sharp}
          small={true}
          up={true}
          isAnalyzing={incomingAudioState.isAnalyzing}
          textColor={detectedNote.textColor}
        ></NoteTextGl>
        <NoteTextGl
          text={detectedNote.octaveString}
          small={true}
          isAnalyzing={incomingAudioState.isAnalyzing}
          textColor={detectedNote.textColor}
        ></NoteTextGl>
        {false && <CentsGl cents={detectedNote.centsError} color={detectedNote.textColor} />}
        {false && <EffectWithBloom></EffectWithBloom>}
      </Canvas>
    </div>
  );
}

// -----------------------------------------------------------------------------
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pressToTune: {
      color: 'white',
    },
    statusTextVert: {
      position: 'absolute',
      top: 0,
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    statusTextHoriz: {
      marginTop: theme.spacing(9),
      padding: theme.spacing(3),
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    bouncyMicContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(4, 2),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 1),
      },
    },
    bouncyMicButton: {
      padding: theme.spacing(3, 5),
      borderRadius: theme.shape.borderRadius * 2,
      '&:hover': {
        backgroundColor: 'rgba(25, 25, 25, 0.2)',
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2, 3),
      },
    },
    bouncyMicIcon: {
      fontSize: 100,
      color: 'white',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: 80,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 60,
      },
    },
    bouncyMicText: {
      color: 'white',
      [theme.breakpoints.up('md')]: {
        fontSize: '3rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '2rem',
      },
    },
    bouncyMicButtonContainer: {
      textTransform: 'none',
      margin: theme.spacing(3),
    },
    adTopBanner: {
      backgroundColor: 'rgba(50, 50, 50, 0.3)',
      marginBottom: theme.spacing(10),
      zIndex: 10,
    },
    adStartPageRectangle: {
      backgroundColor: 'rgba(50, 50, 50, 0.3)',
      marginTop: theme.spacing(10),
      display: 'flex',
      justifyContent: 'center',
      zIndex: 10,
    },
  }),
);

const LPF_AMOUNT_1 = 0.3;
const LPF_AMOUNT_2 = 1.0 - LPF_AMOUNT_1;

// -----------------------------------------------------------------------------
function MySummaryView(p: TuningViewProps) {
  const classes = useStyles();
  const [textColor, setTextColor] = useState('gray');
  const [refFreqText, setRefFreqText] = useState('');
  const [tuningText, setTuningText] = useState('');
  const [centsAvg, setCentsAvg] = useState(0);
  const [freqAvg, setFreqAvg] = useState(0);
  const [incomingState, setIncomingState] = useState<null | AudioState>(null);

  const [centsAvgStr, setCentsAvgStr] = useState('');
  const [freqAvgStr, setFreqAvgStr] = useState('listening...');

  useEffect(() => {
    dbg.log('MySummaryView::Mounting');
    function MySummaryViewNoteEvt(_timestampMs: number, state: AudioState) {
      setIncomingState(state);
    }
    AudioStateHolder.inst().callbacks.subscribe(MySummaryViewNoteEvt);
    return () => {
      dbg.log('MySummaryView::Unmounting');
      AudioStateHolder.inst().callbacks.unsubscribe(MySummaryViewNoteEvt);
    };
  }, []);

  useEffect(() => {
    if (!!incomingState) {
      const state = incomingState;

      let freqIncoming = 0;
      let centsIncoming = 0;
      if (state.isAnalyzing) {
        freqIncoming = state.strobeFreq;
        centsIncoming = state.strobeCents;
      }

      if (state.textColor !== textColor) {
        setTextColor(state.textColor);
      }

      const centsErr = Math.abs(centsIncoming - centsAvg);
      const newCentsAvg =
        centsErr < 20 ? LPF_AMOUNT_1 * centsIncoming + LPF_AMOUNT_2 * centsAvg : centsIncoming;

      if (newCentsAvg !== centsAvg) {
        setCentsAvg(newCentsAvg);

        let newCentsAvgStr = newCentsAvg.toFixed(1);

        if (0 === newCentsAvg) {
          newCentsAvgStr = newCentsAvgStr + ' cent';
        } else if (newCentsAvg > 0) {
          newCentsAvgStr = '+' + newCentsAvgStr + ' cent';
        } else {
          newCentsAvgStr = newCentsAvgStr + ' cent';
        }
        setCentsAvgStr(newCentsAvgStr);
      }
      if (freqIncoming > 0) {
        const freqErr = Math.abs(freqIncoming - freqAvg) / freqIncoming;
        const newFreqAvg =
          freqAvg !== 0 && freqErr < 0.01
            ? LPF_AMOUNT_1 * freqIncoming + LPF_AMOUNT_2 * freqAvg
            : freqIncoming;
        if (newFreqAvg !== freqAvg) {
          setFreqAvg(newFreqAvg);

          const newFreqAvgStr = roundFrequencyStr(newFreqAvg);

          setFreqAvgStr(newFreqAvgStr);
        }
      } else {
        setFreqAvgStr('listening...');
      }

      setIncomingState(null);
    }
  }, [incomingState, freqAvg, centsAvg, textColor]);

  const refFreq = useSelector((state: RootState) => {
    return state.system.referenceFreq;
  });

  const currentTuning = useSelector((state: RootState) => {
    return state.system.currentTuning;
  });

  const currentTransposition = useSelector((state: RootState) => {
    return state.system.transposition;
  });

  const showAmplitude = useSelector((state: RootState) => {
    return state.system.showAmplitude;
  });

  useEffect(() => {
    const s = Math.abs(refFreq - 440) < 0.001 ? '' : 'Reference: ' + refFreq.toFixed(1) + 'Hz';
    setRefFreqText(s);
  }, [refFreq]);

  useEffect(() => {
    let tuningStr = '';
    if (currentTuning) {
      const e: ElementBaseHelper = new ElementBaseHelper(currentTuning);
      const tn = e.getName();
      tuningStr = tn;
    }
    let txt = tuningStr.length === 0 ? 'Chromatic' : tuningStr;
    if (currentTransposition !== 0) {
      txt += ' / ' + transpositionStrings[currentTransposition];
    }
    setTuningText(txt);
  }, [currentTuning, currentTransposition]);

  return (
    <div
      className={classes.statusTextVert}
      style={{
        height: p.canvasDims.height,
        width: p.canvasDims.width,
      }}
    >
      <div className={classes.statusTextHoriz}>
        <NoteView note={tuningText}></NoteView>
        <NoteView note={refFreqText}></NoteView>
      </div>

      <div className={classes.statusTextHoriz}>
        <NoteView big={true} color={textColor} note={freqAvgStr}></NoteView>
        <NoteView big={true} color={textColor} note={centsAvgStr}></NoteView>
      </div>

      <div style={{ position: 'absolute', left: 6, top: 60 }}>
        {showAmplitude && (
          <VuMeter
            top={60}
            left={6}
            width={12}
            height={p.canvasDims.height - VERTICAL_OFFSET_INCLUDING_TITLE_SIZE - 60}
          ></VuMeter>
        )}
      </div>
    </div>
  );
}

export type DimensionsType = {
  top: number;
  left: number;
  x: number;
  y: number;
  width: number;
  height: number;
};

const useSquareAdUnderMic = true;

// -----------------------------------------------------------------------------
function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wakelock = useWakeLock({
    onRequest: () => dbg.log('App::WakeLock::Screen Wake Lock: requested.'),
    onError: (error) => dbg.logObj('App::WakeLock::Could not be obtained 💥', error),
    onRelease: () => dbg.log('App::WakeLock::Screen Wake Lock: released.'),
  });
  const [wakelockRequested, setWakeLockRequested] = useState(false);

  const audioStarted = useSelector((state: RootState) => {
    return state.system.audioStarted;
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (wakelock.isSupported) {
      if (audioStarted) {
        if (!wakelockRequested) {
          setWakeLockRequested(true);
          wakelock.request();
        }
      } else {
        if (wakelockRequested) {
          setWakeLockRequested(false);
          wakelock.release();
        }
      }
    }
  }, [wakelock, audioStarted, wakelockRequested]);

  const [canvasDims, setCanvasDims] = useState(
    newCanvasDims(window.innerWidth, window.innerHeight),
  );

  const [minDim, setMinDim] = useState(Math.min(window.innerWidth, window.innerHeight));

  // Called when the window changes size.
  const updateDimensions = useCallback(() => {
    const { innerWidth: w, innerHeight: h } = window;
    const _minDim = Math.min(window.innerWidth, window.innerHeight);
    setMinDim(_minDim);
    setCanvasDims(newCanvasDims(w, h));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  // Start audio when the user clicks it.
  const onClickStartAudio = useCallback(() => {
    dispatch(Dispatch.startAudio(true));
  }, [dispatch]);

  const [notesListOpen, setNotesListOpen] = useState(false);
  const [lockedNote, setLockedNote] = useState<null | ReduxSpecificNote>(null);
  const [toast, setToast] = useState('');

  useEffect(() => {
    if (lockedNote) {
      setToast('Locked to ' + roundFrequencyStr(lockedNote.freq) + 'Hz');
    } else {
      setToast('Unlocked');
    }

    dispatch(Dispatch.lockToNoteAction(lockedNote));
  }, [lockedNote, dispatch]);

  const closeSnackbar = useCallback(() => {
    setToast('');
  }, []);

  dbg.log('App rerender');

  return (
    <PersistentDrawerLeft
      showNotesListIcon={audioStarted}
      notesListIsOpen={notesListOpen && audioStarted}
      changeNoteListState={() => {
        if (notesListOpen) {
          setLockedNote(null);
        }
        setNotesListOpen((noteListOpen) => !notesListOpen);
      }}
    >
      <>
        <div style={{ margin: 0, padding: 0 }}>
          {!audioStarted && (
            <div>
              {!useSquareAdUnderMic && (
                <AdContainer position='top' size='leaderboard' className={classes.adTopBanner} />
              )}
              <div className={classes.bouncyMicContainer}>
                <Button
                  className={classes.bouncyMicButton}
                  style={{ textTransform: 'none' }}
                  onClick={onClickStartAudio}
                >
                  <BouncyView
                    amountY={minDim / (10 * 8)}
                    loop={true}
                    style={{
                      flexDirection: 'column',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Mic className={classes.bouncyMicIcon} />
                    <Typography className={classes.bouncyMicText} variant='h3'>
                      {t('s_START')}
                    </Typography>
                  </BouncyView>
                </Button>
              </div>

              {useSquareAdUnderMic && (
                <AdContainer
                  position='inline'
                  size='large-rectangle'
                  className={classes.adStartPageRectangle}
                />
              )}
            </div>
          )}
          {audioStarted && <MyGlTuningView canvasDims={canvasDims} />}
          {audioStarted && <MySummaryView canvasDims={canvasDims} />}

          <AnimatedRhNoteSelector
            notesListOpen={notesListOpen && audioStarted}
            left={canvasDims.width - RH_DRAWER_SIZE}
            maxHeight={canvasDims.height - VERTICAL_OFFSET_INCLUDING_TITLE_SIZE - 60}
            height={canvasDims.height - VERTICAL_OFFSET_INCLUDING_TITLE_SIZE - 60}
            noteSelected={(selected: null | RhNoteSelectorSelection) => {
              setLockedNote(selected);
            }}
          />

          <Snackbar
            open={toast.length > 0}
            key={'helpKey222'}
            onClose={closeSnackbar}
            autoHideDuration={3000}
          >
            <Typography>{toast}</Typography>
          </Snackbar>
        </div>
        <CookieConsent
          location='bottom'
          buttonText='Accept'
          cookieName='myCookieConsent'
          visible='byCookieValue'
          hideOnDecline={false}
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
          onAccept={(_acceptedByScrolling: boolean) => {
            dbg.log('Cookies accepted.');
          }}
          onDecline={() => {
            dbg.log('Cookies declined.');
          }}
        >
          {t('s_CookieConsent')}
        </CookieConsent>
      </>
    </PersistentDrawerLeft>
  );
}

// -----------------------------------------------------------------------------
export default withRouter(App);
