import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Store } from '@abstractions/redux_inst';
import App from './App';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { MyTheme } from './palette';
import { AdSenseInitializer } from './components/AdContainer';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import CreateInstrumentsScreen from './tuning_editor/create_instrument_screen';

import '@abstractions/i18n';

const store = Store.inst().store;
const theme = MyTheme;

const PageReloader = () => {
  useEffect(() => {
    window.location.reload();
  }, []);

  return null;
};

// See https://reactrouter.com/web/api/Route
ReactDOM.render(
  <React.StrictMode>
    <AdSenseInitializer />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path='/help.html'>
              <PageReloader />
            </Route>
            <Route exact path='/help_app.html'>
              <PageReloader />
            </Route>

            <Route exact path='/privacy.html'>
              <PageReloader />
            </Route>

            <Route exact path='/privacy_app.html'>
              <PageReloader />
            </Route>

            <Route exact path='/terms_of_service.html'>
              <PageReloader />
            </Route>

            <Route path='/instruments'>
              <CreateInstrumentsScreen />
            </Route>
            <Route path='/share/:url'>
              <CreateInstrumentsScreen />
            </Route>
            <Route exact path='/delete_me.html'>
              <App />
            </Route>

            <Route path='/'>
              <App />
            </Route>
            <Route>
              <App />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
